.loading-container {
    display: flex;
    justify-content: center;
    /* Centrer horizontalement */
    align-items: center;
    /* Centrer verticalement */
    width: 100vw;
    /* Prend toute la largeur de la vue */
    height: 100vh;
    /* Prend toute la hauteur de la vue */
    position: fixed;
    /* S'assure que le loader soit centré même en défilement */
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    /* Optionnel : fond semi-transparent */
    z-index: 1000;
    /* S'assure que le loader soit toujours au-dessus des autres éléments */
}